import React, { useState } from 'react'
import './App.css'



function App() {
  const descriptions = [
    `<p>Leading a team of 10+ Software Developers, DevOps, and QA across two key areas:

<strong>Product Development</strong>
Building and optimizing scalable beverage delivery systems for EU regions, including backend services, mobile application development, server infrastructure, and ERP integrations.

<strong>Web Solutions</strong>
Leading and driving team with pixel-perfect web design, SEO, digital marketing, and performance best practices to strengthen online presence and enhance user engagement.
    </p>`,
    `<p>Assigned to the Buy Now, Pay Later (BNPL) and Consumer Lending team, supporting key financial services within the platform:

<strong>Acquisition (KYC)</strong>
Managed team of software engineers and optimization of the Know Your Customer (KYC) process to streamline user onboarding and ensure regulatory compliance.

<strong>Recovery (Collections)</strong>
Managed team of software engineers to enhance collection strategies, improving repayment efficiency.
    </p>`,
    `<p>Assigned to led two teams of software engineers, supporting Bukalapak's e-commerce ecosystem in the payment and fintech area:

<strong>e-Wallet Integration</strong>
Led the development and integration of popular e-wallets (DANA, OVO) as payment methods, including SDK implementation, PIN authentication, top-ups, and refund handling.

<strong>Reconciliation</strong>
Built tools to support the finance team in reporting and monitoring invoice reconciliation across all third-party payment providers.
    </p>`,
    `<p>As a Software Engineer (Back-end) in the O2O team, I was assigned to the Mitra Bukalapak platform, focusing on:

<strong>Wallets</strong>: Developed and maintained payment integrations, including DANA and BukaDompet, ensuring seamless transactions for Mitra Bukalapak users.

<strong>Mitra Team</strong>: Contributed to onboarding and KYC processes, improving user verification and registration flows to enhance platform accessibility and compliance.
    </p>`,
    `<p>Developed front-end and back-end microservices, desktop applications for various projects serving Indonesian and Japanese clients. Also worked on implementing ARIMA for time series forecasting.
    </p>`,
    `<p>Develop and maintain websites (PHP, CodeIgniter, Laravel, Node.js), enabling online stores (WooCommerce, Opencart), mobile applications (Ionic) and desktop applications (.NET C#).
    </p>`,
    `<p>Maintain computer network and hardware availability, software development and improve implementation (web and desktop applications).
    </p>`,
    `<p>Teaching Assistant for the Software Laboratory Center at Bina Nusantara University.</p>`,
  ]
  const [jobDescription, setJobDescription] = useState(descriptions[0])
  const handleJobClick = (type) => () => {
    setJobDescription(descriptions[type])
  }

  return (
    <>
      <div>
        <header>
          {/* <h4 className="hello">Hi, my name is</h4> */}
          <h1>William Lazuardi.</h1>
          <h4><a href="https://en.wikipedia.org/wiki/Software_engineer" target="_blank" rel="noopener noreferrer">Software Engineer</a> with extensive experience across multiple projects and industries.</h4>
        </header>
      </div>

      <div className="section aboutSection">
        <h2>About me</h2>
        <p>
        I have experience building software, leading teams of software engineers, and collaborating with multidisciplinary teams. I've worked with various design patterns and cloud infrastructure to develop scalable and efficient solutions.
        </p>
        {/* <p>
          Some technologies I'm familiar with:
        </p>
        <ul>
          <li>Go</li>
          <li>Ruby on Rails</li>
          <li>Laravel</li>
          <li>JavaScript</li>
          <li>Node.js</li>
          <li>MySQL</li>
          <li>Postgre</li>
          <li>Redis</li>
          <li>MongoDB</li>
          <li>Docker</li>
          <li>Kubernetes</li>
          <li>Cloud Tech. (GCP, AWS)</li>
        </ul> */}
      </div>

      <div className="section">
        <h2>Experiences</h2>
        <div className="verySmall">*tap on the job title for a description</div>
        <div className="overflow">
        <table>
          <tbody>
          <tr>
              <td className="job" onClick={handleJobClick(0)}>
                <span className="jobTitle">Software Development Manager</span><br />
                <strong><a href="https://commsult.de/" target="_blank" rel="noopener noreferrer">Commsult</a></strong><br />
                May 2024 - Present<br />
                Jakarta
              </td>
              <td className="jobDescription" rowSpan="8">
                <strong>Description</strong>
                <div className="jobDescriptionText" dangerouslySetInnerHTML={{ __html: jobDescription }} />
              </td>
            </tr>
            <tr>
              <td className="job" onClick={handleJobClick(1)}>
                <span className="jobTitle">Engineering Manager</span><br />
                <strong><a href="https://www.crunchbase.com/organization/traveloka" target="_blank" rel="noopener noreferrer">Traveloka</a></strong><br />
                Jan 2022 - Mar 2024<br />
                Jakarta
              </td>
            </tr>
            <tr>
              <td className="job" onClick={handleJobClick(2)}>
                <span className="jobTitle">Engineering Manager</span><br />
                <strong><a href="https://www.crunchbase.com/organization/bukalapak" target="_blank" rel="noopener noreferrer">Bukalapak</a></strong><br />
                Aug 2020 - Jan 2022<br />
                Jakarta
              </td>
            </tr>
            <tr>
              <td className="job" onClick={handleJobClick(3)}>
                <span className="jobTitle">Software Engineer</span><br />
                <strong><a href="https://www.crunchbase.com/organization/bukalapak" target="_blank" rel="noopener noreferrer">Bukalapak</a></strong><br />
                Jul 2018 - Aug 2020<br />
                Jakarta
              </td>
            </tr>
            <tr>
              <td className="job" onClick={handleJobClick(4)}>
                <span className="jobTitle">Software Engineer</span><br />
                <strong><a href="https://www.crunchbase.com/organization/nomura-research-institute" target="_blank" rel="noopener noreferrer">Nomura Research Institute</a></strong><br />
                Jul 2017 - Jun 2018<br />
                Jakarta
              </td>
            </tr>
            <tr>
              <td className="job" onClick={handleJobClick(5)}>
                <span className="jobTitle">Software Developer</span><br />
                <strong><a href="#">Self-employed</a></strong><br />
                Jan 2013 - Dec 2017<br />
              </td>
            </tr>
            <tr>
              <td className="job" onClick={(handleJobClick(6))}>
                <span className="jobTitle">Operations and System<br />Development Officer</span><br />
                <strong><a href="https://www.crunchbase.com/organization/binus-university" target="_blank" rel="noopener noreferrer">Bina Nusantara University</a></strong><br />
                Feb 2014 - Sep 2015<br />
                Jakarta
              </td>
            </tr>
            <tr>
              <td className="job" onClick={(handleJobClick(7))}>
                <span className="jobTitle">Teaching Assistant</span><br />
                <strong><a href="https://www.crunchbase.com/organization/binus-university" target="_blank" rel="noopener noreferrer">Bina Nusantara University</a></strong><br />
                Sep 2012 - Feb 2014<br />
                Jakarta
              </td>
            </tr>
          </tbody>
        </table>
        </div>
        <br />
      </div>

      {/* <div className="section projectSection">
        <h2>Some projects I have been involved</h2>
        <ul>
          <li>
            <a href="https://www.youtube.com/watch?v=mT27MXx_MZQ">Mitra Peduli (Emtek Peduli Corona)</a> development for Mitra Bukalapak, groceries donation, distribution and coupon management.
          </li>
          <li>
            <a href="https://www.youtube.com/watch?v=AVzzerZExRo" target="_blank" rel="noopener noreferrer">QRIS Development</a> for Mitra Bukalapak and integration with bank partner.
          </li>
          <li>
            Migrating several Bukalapak's microservices to cloud infrastructure (GCP).
          </li>
          <li>
            Develop and design an ERP system with Nomura Research Institute team. Mainly focus on developing Operation modules such as: Purchasing, Delivery, Scheduling, Item Management, Reports and POS.
          </li>
          <li>
            PT. Mentari Books Indonesia (mymentari.com, mentarievent.com, mentaripedia.com, mentaridigital.com). I developed complete IT-solutions for this company using Laravel, Express, Magento.
          </li>
          <li>
            IPL Payment System (housing management fee). Online payment solution for residential management (RW/RT) in Cluster Sevilla BSD City. I developed a <a href="https://www.clustersevilla.com/">web application</a> (CodeIgniter), <a href="https://play.google.com/store/apps/details?id=com.clustersevilla.sevillamobileapps&hl=en">Mobile Apps (Ionic)</a> and <a href="https://api.clustersevilla.com/">API (Lumen)</a> for this residential.
          </li>
        </ul>
        <br />
      </div> */}

      <div className="section contactSection">
        <h2>Contacts</h2>
        <ul>
          <li>Mail <a href="mailto: williamlazuardi@gmail.com">williamlazuardi@gmail.com</a></li>
          <li><a href="https://www.linkedin.com/in/william-lazuardi" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
        </ul>

        <footer>
          &nbsp;
        </footer>
      </div>


  </>
  );
}

export default App